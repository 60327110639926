import React from "react";
import BreadCrumbSection from "~sections/about/BreadCrumb";
import ContactSection from "~sections/contact/ContactOne/ContactSection";
import { PageWrapper } from "~components/Core";
import FooterFive from '~sections/agency/FooterFive'

export default function ContactUs() {
  return (
    <PageWrapper innerPage={true}>
        <BreadCrumbSection title="Contact Us" />
        <ContactSection/>
        <FooterFive/>
    </PageWrapper>
  )
}

