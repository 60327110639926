import React from 'react'
import Contact from './style'
import SectionTitle from './Components/SectionTitle'
import { Col, Container, Row } from 'react-bootstrap'
export default function ContactOne(){
return(
<Contact>
  <Container>
    <Row>
      <Col className="col-xl-7 col-lg-7">
        <Contact.Box >
            <SectionTitle
            subTitle="Contact Us" 
            title="Send A Message"
            text=""
            subTitleProps={{mb:"10px"}}
            titleProps={{mb:"10px",as:"h2"}}
            mb="50px" />
        </Contact.Box>
        <Contact.Form>
            <form action="https://getform.io/f/91669278-e98d-47a1-8bc3-1fd012c75e1b" method="POST">
            <Row>
              <Col xs="12" className="col-lg-6 mb-4">
                <div className="form-floating">
                  <input className="form-control" placeholder="Your name" id="email" name="email" type="email" />
                  <label htmlFor="email">Your Email</label>
                </div>
              </Col>
              <Col xs="12" className="col-lg-6 mb-4">
                <div className="form-floating">
                  <input className="form-control" placeholder="Phone Number" id="phonenumber" name="phonenumber" type="tel"/>
                  <label htmlFor="phonenumber">Phone number</label>
                </div>
              </Col>
              <Col xs="12" className="col-lg-12">
                <div className="form-floating">
                  <textarea className="form-control" placeholder="Message" id="message" name="message"/>
                  <label htmlFor="message">Message</label>
                </div>
              </Col>
              <Col xs="12" className="col-lg-12">
                <Row className="align-items-right mt-3">
                  <Col xs="12" className="col-lg-12 text-md-end pt-3">
                    <Contact.Button>Send Message</Contact.Button>
                    {/* <button type="submit">Send</button> */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        </Contact.Form>
      </Col>
      <Col xs="12" className="col-xl-5 col-lg-5">
        <Contact.WidgetsBox className="contact-widget-box" mtLG="60px">
            <Contact.WidgetsBoxTitle as="h2">Get In Touch</Contact.WidgetsBoxTitle>
          <Row>
            <Col xs="12" className="col-lg-12 col-sm-6">
            <Contact.Widgets>
              <Contact.WidgetsIcon>
                <i className="fas fa-envelope" />
              </Contact.WidgetsIcon>
              <Contact.WidgetsBoxBody>
                <Contact.WidgetsTitle as="h3">mail:</Contact.WidgetsTitle>
                <Contact.WidgetsText as="p">simon.townsend@arcpr.ca</Contact.WidgetsText>
              </Contact.WidgetsBoxBody>
              </Contact.Widgets>
            </Col>
            {/* <Col xs="12" className="col-lg-12 col-sm-6">
            <Contact.Widgets>
              <Contact.WidgetsIcon>
                <i className="fas fa-phone-alt" />
              </Contact.WidgetsIcon>
              <Contact.WidgetsBoxBody>
                <Contact.WidgetsTitle as="h3">call:</Contact.WidgetsTitle>
                <Contact.WidgetsText as="p">+8 (123) 985 789</Contact.WidgetsText>
              </Contact.WidgetsBoxBody>
              </Contact.Widgets>
            </Col> */}
          </Row>
        </Contact.WidgetsBox>
      </Col>
    </Row>
  </Container>
</Contact>

)
}