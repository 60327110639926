import { Link } from '~components'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Images } from '~data'
import Footer from "./style"


export default function FooterFive(){
return(
    <Footer backgroundColor="#f3f4f6">
    <Container>
        <Footer.Box pb="25px">
        <Row className="justify-content-center justify-content-lg-between">
            <Col xs="12">
            <Footer.Logo>
                {/* Brand Logo*/}
                <Footer.Box mb="30px">
                    <Link  to="#">
                        <img src={Images.Logo} alt="logo" />
                    </Link>
                </Footer.Box>
            </Footer.Logo>
            
            <Footer.Text as="p">above and beyond</Footer.Text>
            <Footer.Menu>
                <Footer.MenuItems><a href="/">Home</a></Footer.MenuItems>
                <Footer.MenuItems><a href="/#services">Services</a></Footer.MenuItems>
                <Footer.MenuItems> <a href="/about">About</a> </Footer.MenuItems>
                <Footer.MenuItems><a href="/contact-us">Contact</a>
                </Footer.MenuItems>
            </Footer.Menu>
            </Col>
        </Row>
        </Footer.Box>
        <Footer.Copyright>
        {/* <Footer.SocialShare>
            <Footer.SocialShareItem>
                <a href="#">
                <i className="fab fa-linkedin" />
                </a>
            </Footer.SocialShareItem>
        </Footer.SocialShare> */}
        <Footer.CopyrightText>© 2022 Arc PR &amp; Communications. All Rights Reserved</Footer.CopyrightText>
        </Footer.Copyright>
    </Container>
    </Footer>
)
}